import React, { useRef, useState } from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { Context } from '../Context/Context';
import Footer from '../Footer';
import Loader from '../Loader';
const BopForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onChange = data => console.log(data);
    const [phoneNumber, setPhoneNumber] = useState();
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    const mapApi = 'https://maps.googleapis.com/maps/api/js';
    // const geocode = 'https://maps.googleapis.com/maps/api/geocode/json';
    const key = 'AIzaSyCW9qpmLLexwnKdLA-LRHVM63hulApo2qE';
    function loadAsyncScript(src) {
        return new Promise(resolve => {
            const script = document.createElement('script');
            Object.assign(script, {
                type: 'text/javascript',
                async: true,
                src
            })
            script.addEventListener('load', () => resolve(script));
            document.head.appendChild(script);
        })
    }
    const [addressCity, setAddressCity] = useState('');
    const [addressState, setAddressState] = useState('');
    const [addressZip, setAddressZip] = useState('');
    const [addressCountry, setAddressCountry] = useState('');
    const extractAddress = (place) => {
        const address = {
            city: '',
            state: '',
            zip: '',
            country: '',
            plain() {
                const city = this.city ? this.city + ', ' : '';
                const zip = this.zip ? this.zip + ', ' : '';
                const state = this.state ? this.state + ', ' : '';
                return city + zip + state + this.country;
            }
        }
        if (!Array.isArray(place?.address_components)) {
            return address;
        }
        place.address_components.forEach(component => {
            const types = component.types;
            const value = component.long_name;
            if (types.includes('locality')) {
                address.city = value;
                setAddressCity(value);
            }
            if (types.includes('administrative_area_level_2')) {
                address.state = value;
                setAddressState(value);
            }
            if (types.includes('postal_code')) {
                address.zip = value;
                setAddressZip(value);
            }
            if (types.includes('country')) {
                address.country = value;
                setAddressCountry(value);
            }
        })
    }
    const searchAddress = useRef(null);
    const initMapScript = () => {
        if (window.google) {
            return Promise.resolve();
        }
        const src = `${mapApi}?key=${key}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    }
    const onChangeAddress = (autocomplete) => {
        const location = autocomplete.getPlace();
    }
    const initAutocomplete = () => {
        if (!searchAddress.current) return;
        const autocomplete = new window.google.maps.places.Autocomplete(searchAddress.current);
        autocomplete.setFields(['address_component', 'geometry']);
        autocomplete.addListener('place_changed', () => onChangeAddress(autocomplete));
    }
    useEffect(() => {
        initMapScript().then(() => initAutocomplete())
    }, [])
    return (
        <>
            <div className='row bop-container mb-4' style={{ zIndex: '-999999 !important' }}>
                <div className="col-md-7">
                    <form className='bop-form me-auto' onChange={handleSubmit(onChange)}>
                        <h2 style={{ paddingBottom: '30px' }}>Become Our Partner
                            <div className="line"></div>
                        </h2>
                        <div className='row' style={{ width: '100%' }}>
                            <div className="col-md-6 bop-form-input-box">
                                <input type="text" className="form-control" aria-label="First name" required />
                                <span>Full Name</span>
                            </div>
                            <div className="col-md-6 bop-form-input-box">
                                <input type="text" className="form-control" aria-label="First name" required />
                                <span>Company Name</span>
                            </div>
                        </div>
                        <div className='row' style={{ width: '100%' }}>
                            <div className="col-md-6 bop-form-input-box">
                                <input type="text" className="form-control" required {...register("email", {
                                    pattern: {
                                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                        message: 'Enter a Valid email',
                                    },
                                })}></input>
                                <span>Email</span>
                                {errors.email && <div style={{ color: 'red', marginLeft: '10px' }}><i class="bi bi-info-circle" style={{ fontSize: '14px' }}></i> {errors.email?.message}</div>}
                            </div>
                            <div className="col-md-6 bop-form-input-box">
                                <PhoneInput
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                    }}
                                    inputClass='mobile-input mobile-input-ce'
                                    // onlyCountries={['in', 'fr']}
                                    country={'in'}
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                />
                            </div>
                        </div>
                        <div className="row w-100">
                            <div className="col-12 bop-form-input-box">
                            <span style={{ left: 'auto', right: '0px', background: '#ccc', padding: '11px', borderRadius: '5px', cursor: 'pointer' }}><i className="bi bi-geo"></i></span>
                            <input type="text" className="form-control" ref={searchAddress} name="" id="" />
                            </div>
                        </div>
                        <div className="row w-100">
                            <div className="col-12 bop-form-input-box">
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>Choose device type</option>
                                    <option value="1">Laptop</option>
                                    <option value="2">Gaming Consoles</option>
                                    <option value="3">Smart Watches</option>
                                </select>
                            </div>
                        </div>
                        <div className="row w-100">
                            <div className="col-12" style={{ margin: '10px 0', color: 'gray' }}>
                                <span>GST Registration : </span>
                                {/* <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                    <label class="form-check-label" for="inlineRadio2">No</label>
                                </div> */}
                                <input type="text" className="form-control mt-2" aria-label="gst-number" name="gst-number"/>
                            </div>
                        </div>
                        <div className="bop-form-input-box" style={{ margin: '10px 25px 20px 0' }}>
                            <textarea className="form-control" aria-label="With textarea" required></textarea>
                            <span style={{ paddingLeft: '14px' }}>Address</span>
                        </div>
                        <button className="btn btn-primary" type='submit'>Submit</button>
                    </form>
                </div>
                <div className="bop-image col-md-4">
                    <img src={'https://img.freepik.com/free-vector/helping-partner-concept-illustration_114360-8867.jpg?w=740&t=st=1661158672~exp=1661159272~hmac=ce3bcfe0d4ee910810dbc17eb29b60c088eaf0219df6c9dc1268e26f85ff7b0b'} alt="" height={450} />
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default BopForm
