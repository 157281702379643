import React from 'react'

const BottomToTopBtn = () => {
    const scrollTopTop = () => {
        window.scrollTo(0, 0)
    }
    return (
        <button className='bottomToTopBtn' id='bottomToTopBtn' onClick={scrollTopTop} style={{ position: 'fixed', bottom: '20px', right: '20px', width: '50px', height: '40px', fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '99' }}>
            ↑
        </button>
    )
}

export default BottomToTopBtn
