import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Footer from '../Footer';
import { blogList } from './Data';


const TagBlogs = () => {
    const { tag } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [tag]);
    return (
        <div style={{ backgroundColor: '#f5f5f5', marginTop: '-30px' }}>
            <div className='row container mx-auto justify-content-around'>
                <div className="col-md-8 mb-5" style={{ padding: '20px' }}>
                    <h2 style={{ textAlign: 'center', textTransform: 'Capitalize', color: '#111', margin: '50px 0', fontWeight: 'bold', letterSpacing: '2px' }}>Tag : {tag}
                    </h2>
                    {blogList.filter((val) => {
                        if (val.category === tag) {
                            return val;
                        }
                    }).map((v, k) => {
                        return (
                            <div className='blog-wrap' style={{ padding: '10px 40px', backgroundColor: '#fff', borderRadius: '5px', border: '1px solid #ccc', marginTop: '20px' }}>
                                <header>
                                    <p className='blog-date'>Published {v.createdAt}</p>
                                    <Link to={`/blog/${v.title.replaceAll(' ', '-')}`} style={{ textDecoration: 'none', color: '#111', cursor: 'pointer' }}>
                                        <h2 style={{ textAlign: 'left' }}>{v.title}
                                            <div className="line"></div>
                                        </h2>
                                    </Link>
                                    <div className="d-flex justify-content-between align-items-center flex-wrap" style={{ marginBottom: '5px' }}>
                                        <div className='blog-subCategory'>
                                            {v.subCategory.map((category, i) => (
                                                <div key={i} className='blog-desc-chip'>
                                                    {category}
                                                </div>
                                            ))}
                                        </div>
                                        <div className="share-icons">
                                            <a target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${v.title}`}>
                                                <i className="bi bi-facebook mx-1" style={{ fontSize: '20px' }}></i>
                                            </a>
                                            <a target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${v.title}`}>
                                                <i className="bi bi-linkedin mx-1" style={{ fontSize: '20px' }}></i>
                                            </a>
                                            <a target='_blank' href={`https://web.whatsapp.com/send?text={Message}${v.title}`}>
                                                <i className="bi bi-whatsapp mx-1" style={{ fontSize: '20px' }}></i>
                                            </a>
                                            <a target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${v.title}`}>
                                                <i className="bi bi-instagram mx-1" style={{ fontSize: '20px' }}></i>
                                            </a>
                                            <a target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${v.title}`}>
                                                <i className="bi bi-twitter mx-1" style={{ fontSize: '20px' }}></i>
                                            </a>
                                        </div>
                                    </div>
                                </header>
                                <Link to={`/blog/${v.title.replaceAll(' ', '-')}`} style={{ textDecoration: 'none', color: '#111', cursor: 'pointer' }}>
                                    <img src={v.cover} alt='cover' />
                                </Link>
                                <p className='blog-desc'>{v.description}</p>
                            </div>
                        )
                    })
                    }

                </div>
                <div className="col-md-4" style={{ padding: '20px' }}>
                    <div className="recent-blogs" style={{ backgroundColor: '#fff', borderRadius: '5px' }}>
                        <h3 style={{ marginBottom: '0', padding: '23px 20px 15px 20px', borderBottom: '1px solid #f2f2f2' }}>Recent Blogs</h3>
                        {blogList.map((v, k) => {
                            return (
                                <Link to={`/blog/${v.title.replaceAll(' ', '-')}`} style={{ textDecoration: 'none', color: '#111', cursor: 'pointer' }}>
                                    <div className="recent-blogs-card" key={k} style={{ maxHeight: '150px', padding: '15px 20px', cursor: 'pointer', borderBottom: '1px solid #f2f2f2' }}>
                                        <div className="recent-blogs-title">
                                            {v.title}
                                        </div>
                                        <div className="blog-desc-chip" style={{ padding: '6px 10px', marginTop: '10px' }}>
                                            {v.category}
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                    <div className="popular-categories-right" style={{ marginTop: '10px', backgroundColor: '#fff', borderRadius: '5px' }}>
                        <h3 style={{ margin: '30px 0', marginBottom: '0', padding: '23px 20px 15px 20px', borderBottom: '1px solid #f2f2f2' }}>Popular Categories</h3>
                        <div className="categories-box d-flex flex-wrap" style={{ textTransform: 'capitalize', padding: '15px', marginTop: '10px' }}>
                            {blogList.map((v, k) => {
                                return (
                                    <Link to={`/blog/tag/${v.category}`} style={{ textDecoration: 'none', color: '#111' }}>
                                        <div className='catagories-link' style={{ padding: '8px 14px', margin: '7px' }}>
                                            {v.category}
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default TagBlogs
