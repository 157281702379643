import React, { useContext } from 'react'
// import DotLoader from "react-spinners/DotLoader";
// import GridLoader from "react-spinners/GridLoader";
import HashLoader from "react-spinners/HashLoader";
import { Context } from './Context/Context';
const Loader = () => {
    const { loader } = useContext(Context);
    return (
        <div className="d-flex align-items-center justify-content-center w-100 position-absolute" style={loader ? { height: '60vh' } : { height: '100vh' }}>
            <HashLoader
                color="#2f44ff"
                loading={loader}
                speedMultiplier={1}
            />
            {/* <GridLoader
                color="#2f44ff"
                loading={loader}
                margin={2}
                size={15}
                speedMultiplier={1}
                width={0}
                cssOverride={{
                    display: "block",
                    zIndex: '1'
                }}
            /> */}
            {/* <DotLoader
                color="#2f44ff"
                cssOverride={{
                    display: "block",
                    zIndex: '1'
                }}
                loading={loader}
                size={35}
                speedMultiplier={2}
            /> */}
        </div>
    )
}

export default Loader
