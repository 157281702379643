import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer';
const SellProduct = () => {
    const [toast, setToast] = useState('');
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const submit = () => {
        fetchData();
        setToast('show');
    }
    function getCookie() {
        let name = "XSRF-TOKEN=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    const fetchData = () => {
        fetch(`https://take2cash-admin.sumayinfotech.com/api/place_ord`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-XSRF-TOKEN': decodeURIComponent(getCookie()),
            },
            body: JSON.stringify({ s_id: localStorage.getItem('series-id'), mobile_number: localStorage.getItem('phoneNumber'), email: localStorage.getItem('userEmail'), name: localStorage.getItem('userName'), country: addressCountry, zip: addressZip, state: addressState, city: addressCity, block_no: blockNo, vpa: upi, accno: accountNumber, accholdername: accountHolderName, ifsc: Ifsc, apt_dt: datetime})
        })
            .then((response) => { return response.json() })
            .then((fetchedData) => setData(fetchedData))
            .catch((error) => console.log(error))
    }
    const [accountNumber, setAccountNumber] = useState('');
    const [accountHolderName, setAccountHolderName] = useState('');
    const [Ifsc, setIfsc] = useState('');
    const [blockNo, setBlockNo] = useState('');
    const [datetime, setDatetime] = useState('');
    const [upi, setUpi] = useState('');
    const [phoneNumber, setPhoneNumber] = useState();
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    const mapApi = 'https://maps.googleapis.com/maps/api/js';
    // const geocode = 'https://maps.googleapis.com/maps/api/geocode/json';
    const key = 'AIzaSyCW9qpmLLexwnKdLA-LRHVM63hulApo2qE';
    function loadAsyncScript(src) {
        return new Promise(resolve => {
            const script = document.createElement('script');
            Object.assign(script, {
                type: 'text/javascript',
                async: true,
                src
            })
            script.addEventListener('load', () => resolve(script));
            document.head.appendChild(script);
        })
    }
    const [addressCity, setAddressCity] = useState(' ');
    const [addressState, setAddressState] = useState(' ');
    const [addressZip, setAddressZip] = useState(' ');
    const [addressCountry, setAddressCountry] = useState(' ');
    const extractAddress = (place) => {
        const address = {
            city: '',
            state: '',
            zip: '',
            country: '',
            plain() {
                const city = this.city ? this.city + ', ' : '';
                const zip = this.zip ? this.zip + ', ' : '';
                const state = this.state ? this.state + ', ' : '';
                return city + zip + state + this.country;
            }
        }
        if (!Array.isArray(place?.address_components)) {
            return address;
        }
        place.address_components.forEach(component => {
            const types = component.types;
            const value = component.long_name;
            if (types.includes('locality')) {
                address.city = value;
                setAddressCity(value);
            }
            if (types.includes('administrative_area_level_2')) {
                address.state = value;
                setAddressState(value);
            }
            if (types.includes('postal_code')) {
                address.zip = value;
                setAddressZip(value);
            }
            if (types.includes('country')) {
                address.country = value;
                setAddressCountry(value);
            }
        })
    }
    const searchAddress = useRef(null);
    const initMapScript = () => {
        if (window.google) {
            return Promise.resolve();
        }
        const src = `${mapApi}?key=${key}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    }
    const onChangeAddress = (autocomplete) => {
        const location = autocomplete.getPlace();
        extractAddress(location);
    }
    const initAutocomplete = () => {
        if (!searchAddress.current) return;
        const autocomplete = new window.google.maps.places.Autocomplete(searchAddress.current);
        autocomplete.setFields(['address_component', 'geometry']);
        autocomplete.addListener('place_changed', () => onChangeAddress(autocomplete));
    }
    useEffect(() => {
        initMapScript().then(() => initAutocomplete())
    }, [])
    // const reverseGeocode = ({latitude: lat, longtitude: lng})=>{
    //     const url = `${geocode}?key=${key}&latlng=${lat},${lng}`;
    //     searchAddress.current.value = 'Getting your location...';
    //     fetch(url).then(response => response.json())
    //     .then(location =>{
    //         const place = location.results[0];
    //         searchAddress.current.value = extractAddress(place).plain();
    //     })
    // }
    // const findMyAddress = ()=>{
    //     if(navigator.geolocation){
    //         navigator.geolocation.getCurrentPosition(position=>{
    //             reverseGeocode(position.coords)
    //         })
    //     }
    // }
    const validateAccountNumber = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setAccountNumber(e.target.value)
        }
    }
    const validateIfsc = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setIfsc(e.target.value)
        }
    }
    const checkPaymentType = () => {
        var upi = document.getElementById('upi');
        var bank = document.getElementById('bank');
        var cash = document.getElementById('cash');
        var upiPayment = document.getElementById('upiPayment');
        var bankTransfer = document.getElementById('bankTransfer');
        if (upi.checked) { upiPayment.style.display = 'block'; bankTransfer.style.display = 'none' }
        if (bank.checked) { bankTransfer.style.display = 'block'; upiPayment.style.display = 'none' }
        if (cash.checked) { bankTransfer.style.display = 'none'; upiPayment.style.display = 'none' }
    }
    const [data, setData] = useState([]);
    function getCookie() {
        let name = "XSRF-TOKEN=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    return (
        <div>
            <div className="container bop-container">
                <form className='bop-form me-auto' onSubmit={handleSubmit(submit)}>
                    <h2 style={{ paddingBottom: '10px' }}>Sell Your Product (Final Price: Rs. {localStorage.getItem('estimate')})
                        <div className="line"></div>
                    </h2>
                    <div className='row' style={{ width: '100%' }}>
                        <div className="col-md-12 bop-form-input-box">
                            <input type="text" className="form-control" aria-label="First name" required value={localStorage.getItem('userName')} />
                            <span>Full Name</span>
                        </div>
                    </div>
                    <div className='row' style={{ width: '100%' }}>
                        <div className="col-md-6 bop-form-input-box">
                            <input type="text" className="form-control" required {...register("email", {
                                pattern: {
                                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                    message: 'Enter a Valid email',
                                },
                            })}></input>
                            <span>Email</span>
                            {errors.email && <div style={{ color: 'red', marginLeft: '10px' }}><i className="bi bi-info-circle" style={{ fontSize: '14px' }}></i> {errors.email?.message}</div>}
                        </div>
                        <div className="col-md-6 bop-form-input-box">
                            <PhoneInput
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                }}
                                inputclassName='mobile-input mobile-input-ce'
                                // onlyCountries={['in', 'fr']}
                                country={'in'}
                                value={phoneNumber}
                                onChange={setPhoneNumber}
                            />
                        </div>
                    </div>
                    <div className='row' style={{ width: '100%' }}>
                        <div className="col-md-12 bop-form-input-box">
                            <input type="text" className="form-control" aria-label="Block No."  value={blockNo} onChange={(e)=>{setBlockNo(e.target.value)}} required/>
                            <span>House No. / Block No.</span>
                        </div>
                    </div>
                    <div className="row w-100">
                        <div className="col-12 bop-form-input-box" style={{ position: 'relative' }}>
                            <span style={{ left: 'auto', right: '0px', background: '#ccc', padding: '11px', borderRadius: '5px', cursor: 'pointer' }}><i className="bi bi-geo"></i></span>
                            <input type="text" className="form-control" ref={searchAddress} name="" id="" placeholder='Enter your location'/>
                            {/* <div style={{position: 'absolute', marginTop: '-35px', marginLeft: '85%'}}><i className="bi bi-geo"></i></div> */}
                            {/* <input type="text" className="form-control" aria-label="City/State/Country" required />
                            <span>City / State / Country</span> */}
                        </div>
                    </div>
                    <div className="row w-100">
                        <div className="col-12 bop-form-input-box">
                            <span style={{ color: 'gray', position: 'relative', padding: '0', marginRight: '10px' }}>Appointment Date and Time : </span><input type="datetime-local" required value={datetime} onChange={(e)=>{setDatetime(e.target.value)}}/>
                        </div>
                    </div>
                    <div className="row w-100">
                        <div className="col-12 d-flex flex-wrap" style={{ margin: '10px 0', color: 'gray' }}>
                            <span>Payment Type : </span>
                            <label htmlFor="upi" style={{ cursor: 'pointer' }}>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="upi" value="option1" onChange={checkPaymentType} style={{ position: 'static' }} />
                                    <span className='form-check-label'>UPI</span>
                                </div>
                            </label>
                            <label htmlFor="bank" style={{ cursor: 'pointer' }}>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="bank" value="option2" onChange={checkPaymentType} style={{ position: 'static' }} />
                                    <span className="form-check-label">Bank Account Transfer</span>
                                </div>
                            </label>
                            <label htmlFor="cash" style={{ cursor: 'pointer' }}>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="cash" value="option3" onChange={checkPaymentType} style={{ position: 'static' }} />
                                    <span className="form-check-label">Cash</span>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="row w-100" id='upiPayment' style={{ display: 'none' }}>
                        <div className="col-12" style={{ margin: '10px 0', color: 'gray' }}>
                            <input type="text" className="form-control" name="" id="" placeholder='Enter UPI ID' pattern='^[\w\.\-_]{3,}@[a-zA-Z]{3,}' value={upi} onChange={(e)=>{setUpi(e.target.value)}}/>
                        </div>
                    </div>
                    <div className="row w-100" id='bankTransfer' style={{ display: 'none' }}>
                        <div className="col-12" style={{ margin: '10px 0', color: 'gray' }}>
                            <input type="text" className="form-control" name="" id="" placeholder='Account Holder Name' style={{ margin: '10px 0' }} value={accountHolderName} onChange={(e)=>{setAccountHolderName(e.target.value)}}/>
                            <input type="text" className="form-control" name="" id="" placeholder='Account Number' style={{ margin: '10px 0' }} minLength={9} maxLength={15} onChange={(e)=>{validateAccountNumber(e.target.value)}} value={accountNumber} />
                            <input type="text" className="form-control" name="" id="" placeholder='IFSC CODE' style={{ margin: '10px 0' }} maxLength={11} onChange={(e)=>{validateIfsc(e.target.value)}} value={Ifsc} />
                        </div>
                    </div>
                    {/* <div className="bop-form-input-box" style={{ margin: '10px 25px 20px 0' }}>
                        <textarea className="form-control" aria-label="With textarea" required></textarea>
                        <span style={{ paddingLeft: '14px' }}>Address</span>
                    </div> */}
                    <button className="btn btn-primary" type='submit'>Submit</button>
                </form>
            </div>
            <div className="toast-container position-fixed bottom-0 end-0 p-3" style={{ zIndex: '9999999999999 !important' }}>
                <div id="liveToast" className={`toast ${toast}`} role="alert" aria-live="assertive" aria-atomic="true" delay={5000} autohide={true}>
                    <div className="toast-header" style={{ background: '#1fbc32', color: '#fff' }}>
                        <i class="bi bi-check2-circle" style={{ fontSize: '20px' }}></i>
                        <span className="me-auto ms-2">Bootstrap</span>
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="toast" onClick={() => { setToast(''); }} aria-label="Close"></button>
                    </div>
                    <div className="toast-body">
                        Request placed Successfully
                        <div className='btn btn-primary' onClick={()=>{navigate('/');}}>Back To Home</div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default SellProduct
