import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../Footer';
import $ from 'jquery';

const ProductProblems = () => {
    const { brandName, series } = useParams();
    const [data, setData] = useState([]);
    const [finalData, setFinalData] = useState([]);
    const [toast, setToast] = useState('');
    const [toastBackground, setToastBackground] = useState('#fff');
    function getCookie() {
        let name = "XSRF-TOKEN=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-XSRF-TOKEN': decodeURIComponent(getCookie()),
        },
        credentials: "include",
        body: JSON.stringify({ d_id: localStorage.getItem('deviceId'), b_id: localStorage.getItem('b-id') })
    };
    const fetchData = () => {
        fetch('https://take2cash-admin.sumayinfotech.com/api/problem-listing', options)
            .then((response) => { return response.json() })
            .then((fetchedData) => setData(fetchedData))
            .catch((error) => console.log(error))
    }
    useEffect(() => {
        fetchData();
        window.scrollTo(0, 0);
    }, []);
    const fetchFinalData = () => {
        fetch('https://take2cash-admin.sumayinfotech.com/api/cal_est', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-XSRF-TOKEN': decodeURIComponent(getCookie()),
            },
            credentials: "include",
            body: JSON.stringify({ d_id: localStorage.getItem('deviceId'), b_id: localStorage.getItem('b-id'), s_id: localStorage.getItem('series-id'), problems: JSON.parse(localStorage.getItem('problems')), config: JSON.parse(localStorage.getItem('selectedConfig')) })
        })
            .then((response) => { return response.json() })
            .then((fetchedData) => {setFinalData(fetchedData); localStorage.setItem('estimate', fetchedData.estimate);})
            .catch((error) => console.log(error))
    }
    const navigate = useNavigate();
    const navigateToSellProduct = (e) => {
        e.preventDefault();
        var problems = [];
        $('.problem-item:checked').map((e) => {
            problems.push($('.problem-item:checked')[e].id)
        });
        localStorage.setItem('problems', JSON.stringify(problems))
        var btn = document.getElementById('final-price-continue-btn');
        if (localStorage.getItem('isLoggedIn') === 'true') { fetchFinalData(); btn.setAttribute('data-bs-toggle', 'modal'); btn.setAttribute('data-bs-target', '#finalPrice') }
        else { setToast('show'); setToastBackground('#ff432d'); document.getElementById('nav-login-btn').click() }

    }
    return (
        <div>
            <div className='px-md-5' style={{ padding: '0 30px' }}>
                <h2 className='pb-2 pt-4'>Sell your Product ({`${brandName} - ${series.replace('-', ' ')}`})
                    <div className="line"></div>
                </h2>
                <div className="row mt-4 mb-4">
                    <form className="left-side col-md-8" onSubmit={navigateToSellProduct}>
                        <div className="container pt-3 problems-container">
                            <div className="text-center" style={{ fontSize: '22px' }}>Problems</div>
                            <div className=''>
                                <div className="d-flex flex-wrap" style={{ flexDirection: 'row' }}>
                                    {data && data.map((value, key) => {
                                        return (
                                            <div key={key}>
                                                <input className='problem-item' type="checkbox" name="problem" id={value.id} />
                                                <div>
                                                    <label htmlFor={value.id}>
                                                        <div className="card problems-card" >
                                                            <div className="d-flex justify-content-center align-items-center" style={{ height: '100px', background: '#f2f2f2', borderRadius: '5px' }}>
                                                                <img src={'//take2cash-admin.sumayinfotech.com/' + value.logo} className="card-img-top" alt="..." style={{ width: '100%', height: '80%' }} />
                                                            </div>
                                                            <div className="card-body">
                                                                <h5 className="card-title">{value.question}</h5>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="d-flex justify-content-center my-3">
                                <button className='btn btn-primary' id='final-price-continue-btn' type='submit'> Continue →</button>
                            </div>
                        </div>
                    </form>
                    <div className="right-side col-md-4" style={{ border: '1px solid #ccc', padding: '20px' }}>
                        <div className="d-flex justify-content-center">
                            <i className="bi bi-laptop" style={{ fontSize: '60px', marginRight: '20px' }}></i>
                            <div className='align-self-center' style={{ fontSize: '30px' }}>{series.replace('-', ' ')}</div>
                        </div>
                        <div className="estimated-price text-center">
                            Get Upto: Rs. {localStorage.getItem('maxAmount')}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="finalPrice" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="finalPriceLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="finalPriceLabel">Final Price</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex align-items-center">
                                <div className="final-product-image" style={{ padding: '20px' }}>
                                    <i className='bi bi-laptop' style={{ fontSize: '50px' }}></i>
                                </div>
                                <div className="final-product-content">
                                    <div className="final-product-name">Product : {brandName} {series}</div>
                                    <div className="final-product-price">Amount : Rs. {finalData.estimate}</div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => { navigate(`${`/sell-laptop/${brandName}/${series}/sellproduct`}`) }}>Understood</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="toast-container position-fixed bottom-0 end-0 p-3" style={{ zIndex: '9999999999999 !important' }}>
                <div id="liveToast" className={`toast ${toast}`} role="alert" aria-live="assertive" aria-atomic="true" delay={5000} autohide={true}>
                    <div className="toast-header" style={{ background: `${toastBackground}`, color: '#fff' }}>
                        <i class="bi bi-check2-circle" style={{ fontSize: '20px' }}></i>
                        <span className="me-auto ms-2">Bootstrap</span>
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="toast" onClick={() => { setToast(''); }} aria-label="Close"></button>
                    </div>
                    <div className="toast-body">
                        Please login first.
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ProductProblems
