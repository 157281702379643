import React, { useEffect } from 'react'
import img from './images/our-services.png'
import img2 from './images/our-services2.png'
import img3 from './images/our-services3.png'
import img4 from './images/our-services4.png'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from './Footer'
const AboutUs = () => {
    const options = {
        responsive: {
            0: {
                items: 1,
            },
            500: {
                items: 2,
            },
            1000: {
                items: 3,
            }
        }
    }
    useEffect(()=>{
        window.scrollTo(0,0);
    });
    return (
        <>
        <div className='container-fluid mb-4' style={{marginTop: '30px'}}>
            <h2 className='px-md-5' style={{ fontSize: '40px' }}>About Us
                <div className="line"></div>
            </h2>
            <div className='d-flex align-items-center justify-content-around flex-wrap'>
                <div style={{ maxWidth: '500px' }}>
                    <h2 className=''>What we Do
                    </h2>
                    <div className="">
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Earum distinctio, officiis sed natus exercitationem animi atque, quisquam, reiciendis dolore tenetur magnam ullam ad eius veritatis?
                    </div>
                </div>
                <div className="">
                    <img src={'https://img.freepik.com/premium-vector/people-working-together-illustration_52683-25033.jpg?w=740'} alt="" height={300} />
                </div>
            </div>
            <div className="" style={{ padding: '0 30px' }}>
                <h2 style={{ paddingTop: '40px' }}>Services We Provide
                    <div className="line"></div>
                </h2>
                <div className="row d-flex justify-content-center flex-md-nowrap mt-5" style={{ borderRadius: '5px', }}>
                    <div className="col-md-3 card mx-2" style={{ border: '0', padding: '15px 5px' }}>
                        <div className="our-services-icon mx-auto pt-3" style={{ marginBottom: '-10px' }}>
                            <img src={img} className="card-img-top" alt="..." height={150} />
                        </div>
                        <div className="card-body text-center">
                            <h5 className="card-title text-center" style={{ margin: '10px 0' }}>Sell Used Laptop</h5>
                            <p className="card-text" style={{ fontSize: '14px' }}>Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                    </div>
                    <div className="col-md-3 card" style={{ border: '0', padding: '15px 5px' }}>
                        <div className="our-services-icon mx-auto pt-3" style={{ marginBottom: '-10px' }}>
                            <img src={img2} className="card-img-top" alt="..." height={150} />
                        </div>
                        <div className="card-body text-center">
                            <h5 className="card-title text-center" style={{ margin: '10px 0' }}>Repair My Laptop</h5>
                            <p className="card-text" style={{ fontSize: '14px' }}>Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                    </div>
                    <div className="col-md-3 card mx-2" style={{ border: '0', padding: '15px 5px' }}>
                        <div className="our-services-icon mx-auto pt-3" style={{ marginBottom: '-10px' }}>
                            <img src={img3} className="card-img-top" alt="..." height={150} />
                        </div>
                        <div className="card-body text-center">
                            <h5 className="card-title text-center" style={{ margin: '10px 0' }}>Rent A Laptop</h5>
                            <p className="card-text" style={{ fontSize: '14px' }}>Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                    </div>
                    <div className="col-md-3 card" style={{ border: '0', padding: '15px 5px' }}>
                        <div className="our-services-icon mx-auto pt-3" style={{ marginBottom: '-10px' }}>
                            <img src={img4} className="card-img-top" alt="..." height={150} />
                        </div>
                        <div className="card-body text-center">
                            <h5 className="card-title text-center" style={{ margin: '10px 0' }}>Buy Refurbished Laptop</h5>
                            <p className="card-text" style={{ fontSize: '14px' }}>Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="testimonial" style={{ padding: '0 30px' }}>
                <h2 style={{ paddingTop: '40px' }}>Services We Provide
                    <div className="line"></div>
                </h2>
                <OwlCarousel
                    className="owl-theme row justify-content-center pt-5"
                    nav
                    dots={false}
                    margin={9}
                    {...options}
                >
                    <div className="item">
                        <div className="card" style={{ width: '100%', padding: '10px', background: '#f2f2f2', border: '0' }}>
                            <div className="card-body" style={{ padding: '8px 10px' }}>
                                <p className="card-text testimonial-card-text"><i className="bi bi-quote"></i><span> Some quick example text to build on the card title and make up the bulk of the card's content.</span></p>
                            </div>
                            <div className="testimonial-card-footer">
                                <div className="testimonial-profile-pic">
                                    <img src={'https://imgs.search.brave.com/cjqcJ0uAqus65gdaToSbka8df0CSZs03OCa9hp5FJkw/rs:fit:300:300:1/g:ce/aHR0cHM6Ly93d3cu/ZmFpcnRyYXZlbDR1/Lm9yZy93cC1jb250/ZW50L3VwbG9hZHMv/MjAxOC8wNi9zYW1w/bGUtcHJvZmlsZS1w/aWMtMzAweDMwMC5w/bmc'} className="card-img-bottom" alt="..." />
                                </div>
                                <p className='card-title testimonial-card-title'>James Harvey
                                    <p className="card-text testimonial-designation text-muted">~ Director</p>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="card" style={{ width: '100%', padding: '10px', background: '#f2f2f2', border: '0' }}>
                            <div className="card-body" style={{ padding: '8px 10px' }}>
                                <p className="card-text testimonial-card-text"><i className="bi bi-quote"></i><span> Some quick example text to build on the card title and make up the bulk of the card's content.</span></p>
                            </div>
                            <div className="testimonial-card-footer">
                                <div className="testimonial-profile-pic">
                                    <img src={'https://imgs.search.brave.com/cjqcJ0uAqus65gdaToSbka8df0CSZs03OCa9hp5FJkw/rs:fit:300:300:1/g:ce/aHR0cHM6Ly93d3cu/ZmFpcnRyYXZlbDR1/Lm9yZy93cC1jb250/ZW50L3VwbG9hZHMv/MjAxOC8wNi9zYW1w/bGUtcHJvZmlsZS1w/aWMtMzAweDMwMC5w/bmc'} className="card-img-bottom" alt="..." />
                                </div>
                                <p className='card-title testimonial-card-title'>James Harvey
                                    <p className="card-text testimonial-designation text-muted">~ Director</p>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="card" style={{ width: '100%', padding: '10px', background: '#f2f2f2', border: '0' }}>
                            <div className="card-body" style={{ padding: '8px 10px' }}>
                                <p className="card-text testimonial-card-text"><i className="bi bi-quote"></i><span> Some quick example text to build on the card title and make up the bulk of the card's content.</span></p>
                            </div>
                            <div className="testimonial-card-footer">
                                <div className="testimonial-profile-pic">
                                    <img src={'https://imgs.search.brave.com/cjqcJ0uAqus65gdaToSbka8df0CSZs03OCa9hp5FJkw/rs:fit:300:300:1/g:ce/aHR0cHM6Ly93d3cu/ZmFpcnRyYXZlbDR1/Lm9yZy93cC1jb250/ZW50L3VwbG9hZHMv/MjAxOC8wNi9zYW1w/bGUtcHJvZmlsZS1w/aWMtMzAweDMwMC5w/bmc'} className="card-img-bottom" alt="..." />
                                </div>
                                <p className='card-title testimonial-card-title'>James Harvey
                                    <p className="card-text testimonial-designation text-muted">~ Director</p>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="card" style={{ width: '100%', padding: '10px', background: '#f2f2f2', border: '0' }}>
                            <div className="card-body" style={{ padding: '8px 10px' }}>
                                <p className="card-text testimonial-card-text"><i className="bi bi-quote"></i><span> Some quick example text to build on the card title and make up the bulk of the card's content.</span></p>
                            </div>
                            <div className="testimonial-card-footer">
                                <div className="testimonial-profile-pic">
                                    <img src={'https://imgs.search.brave.com/cjqcJ0uAqus65gdaToSbka8df0CSZs03OCa9hp5FJkw/rs:fit:300:300:1/g:ce/aHR0cHM6Ly93d3cu/ZmFpcnRyYXZlbDR1/Lm9yZy93cC1jb250/ZW50L3VwbG9hZHMv/MjAxOC8wNi9zYW1w/bGUtcHJvZmlsZS1w/aWMtMzAweDMwMC5w/bmc'} className="card-img-bottom" alt="..." />
                                </div>
                                <p className='card-title testimonial-card-title'>James Harvey
                                    <p className="card-text testimonial-designation text-muted">~ Director</p>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="card" style={{ width: '100%', padding: '10px', background: '#f2f2f2', border: '0' }}>
                            <div className="card-body" style={{ padding: '8px 10px' }}>
                                <p className="card-text testimonial-card-text"><i className="bi bi-quote"></i><span> Some quick example text to build on the card title and make up the bulk of the card's content.</span></p>
                            </div>
                            <div className="testimonial-card-footer">
                                <div className="testimonial-profile-pic">
                                    <img src={'https://imgs.search.brave.com/cjqcJ0uAqus65gdaToSbka8df0CSZs03OCa9hp5FJkw/rs:fit:300:300:1/g:ce/aHR0cHM6Ly93d3cu/ZmFpcnRyYXZlbDR1/Lm9yZy93cC1jb250/ZW50L3VwbG9hZHMv/MjAxOC8wNi9zYW1w/bGUtcHJvZmlsZS1w/aWMtMzAweDMwMC5w/bmc'} className="card-img-bottom" alt="..." />
                                </div>
                                <p className='card-title testimonial-card-title'>James Harvey
                                    <p className="card-text testimonial-designation text-muted">~ Director</p>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="card" style={{ width: '100%', padding: '10px', background: '#f2f2f2', border: '0' }}>
                            <div className="card-body" style={{ padding: '8px 10px' }}>
                                <p className="card-text testimonial-card-text"><i className="bi bi-quote"></i><span> Some quick example text to build on the card title and make up the bulk of the card's content.</span></p>
                            </div>
                            <div className="testimonial-card-footer">
                                <div className="testimonial-profile-pic">
                                    <img src={'https://imgs.search.brave.com/cjqcJ0uAqus65gdaToSbka8df0CSZs03OCa9hp5FJkw/rs:fit:300:300:1/g:ce/aHR0cHM6Ly93d3cu/ZmFpcnRyYXZlbDR1/Lm9yZy93cC1jb250/ZW50L3VwbG9hZHMv/MjAxOC8wNi9zYW1w/bGUtcHJvZmlsZS1w/aWMtMzAweDMwMC5w/bmc'} className="card-img-bottom" alt="..." />
                                </div>
                                <p className='card-title testimonial-card-title'>James Harvey
                                    <p className="card-text testimonial-designation text-muted">~ Director</p>
                                </p>
                            </div>
                        </div>
                    </div>
                </OwlCarousel >
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default AboutUs
