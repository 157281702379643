import React, { useState } from 'react'
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import Footer from '../Footer';
const CeForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onChange = data => console.log(data);
    const [phoneNumber, setPhoneNumber] = useState('');
    const validatePhoneNumber = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setPhoneNumber(e.target.value)
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    })
    return (
        <>
            <div className='row ce-container'>
                <div className="col-md-7">
                    <form className='ce-form me-auto' onChange={handleSubmit(onChange)}>
                        <h2 style={{ paddingBottom: '30px' }}>Corporate Enquiry
                            <div className="line"></div>
                        </h2>
                        <div className='row' style={{ width: '100%' }}>
                            <div className="col-md-6 ce-form-input-box">
                                <input type="text" name="Company-Name" className="form-control" aria-label="company name" required />
                                <span>Company Name</span>
                            </div>
                            <div className="col-md-6 ce-form-input-box">
                                <input type="text" name="Contact-Person" className="form-control" aria-label="Contact Person" required />
                                <span>Contact Person</span>
                            </div>
                        </div>
                        <div className='row' style={{ width: '100%' }}>
                            <div className="col-md-6 ce-form-input-box">
                                <input type="text" className="form-control" required {...register("email", {
                                    pattern: {
                                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                        message: 'Enter a Valid email',
                                    },
                                })}></input>
                                <span>Email</span>
                                {errors.email && <div style={{ color: 'red', marginLeft: '10px' }}><i class="bi bi-info-circle" style={{ fontSize: '14px' }}></i> {errors.email?.message}</div>}
                            </div>
                            <div className="col-md-6 ce-form-input-box">
                                <PhoneInput
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                    }}
                                    inputClass='mobile-input mobile-input-ce'
                                    // onlyCountries={['in', 'fr']}
                                    country={'in'}
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                />
                            </div>
                        </div>
                        <div className="ce-form-input-box" style={{ margin: '10px 25px 20px 0' }}>
                            <textarea className="form-control" aria-label="With textarea" required></textarea>
                            <span style={{ paddingLeft: '14px' }}>Description about the Requirement</span>
                        </div>
                        <button className="btn btn-primary" type='submit'>Submit</button>
                    </form>
                </div>
                <div className="ce-image col-md-4">
                    <img src={'https://img.freepik.com/free-vector/flat-design-illustration-customer-support_23-2148887720.jpg?w=740&t=st=1661239026~exp=1661239626~hmac=fc1c50369f1cd9bce060d2c2e99d93eced4b15904c50e0106fd56e842bb28850'} alt="" height={450} />
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default CeForm
