import React from 'react'
import BottomToTopBtn from '../components/BottomToTopBtn'
import Footer from '../components/Footer'
import Catagories from '../components/HomePageComponents/Catagories'
import OurServices from '../components/HomePageComponents/OurServices'
import ProcessFlow from '../components/HomePageComponents/ProcessFlow'
// import SellReplairTabs from '../components/HomePageComponents/SellReplairTabs'
import Slider from '../components/HomePageComponents/Slider'
import Testimonial from '../components/HomePageComponents/Testimonial'
import TimeLine from '../components/HomePageComponents/TimeLine'

const HomePage = () => {
  return (
    <div>
      <BottomToTopBtn/>
      <Slider/>
      <OurServices/>
      {/* <SellReplairTabs/> */}
      <Catagories/>
      <TimeLine/>
      <ProcessFlow/>
      <Testimonial/>
      <Footer/>
    </div>
  )
}

export default HomePage
