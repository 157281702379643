import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Context } from '../Context/Context';
import Loader from '../Loader';
import Footer from '../Footer';

const LaptopBrands = () => {
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const { setBrandName, setLoader } = useContext(Context);
    function getCookie() {
        let name = "XSRF-TOKEN=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-XSRF-TOKEN': decodeURIComponent(getCookie()),
        },
        body: JSON.stringify({ d_id: localStorage.getItem('deviceId') })
    };
    const fetchData = () => {
        fetch('https://take2cash-admin.sumayinfotech.com/api/brand-listing', options)
            .then((response) => { return response.json() })
            .then((fetchedData) => setData(fetchedData.data))
            .catch((error) => console.log(error))
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        setLoader(true);
        fetchData();
        setTimeout(() => {
            setLoader(false);
            setLoaded(true)
        }, 2000);
        // eslint-disable-next-line
    }, [])
    return (
        <>
            {loaded ?
                <div>
                    <div className="container-fluid brands px-md-5" style={{ padding: '0 30px' }}>
                        <h2 style={{ paddingTop: '30px' }}>Sell your Laptop
                            <div className="line"></div>
                        </h2>
                        <h3 style={{ paddingTop: '40px' }}>Choose Brand </h3>
                        <div className="mt-5 d-flex flex-wrap" style={{ borderRadius: '5px', }}>
                            {data && data.map((value, key) => {
                                return (
                                    <Link to={`/sell-laptop/${value.name}`} key={key}>
                                        <div className="card" style={{ border: '0', padding: '15px 5px' }} onClick={() => { localStorage.setItem('b-id', value.id); setBrandName(value.name) }}>
                                            <div className="brands-icon mx-auto pt-3" style={{ marginBottom: '-10px' }}>
                                                <img src={value.logo} alt={value.logo} height={60} width={60} />
                                            </div>
                                            <div className="card-body text-center">
                                                <h5 className="card-title text-center" style={{ margin: '20px 0' }}>{value.name}</h5>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                    <Footer />

                </div>
                : <Loader />}
        </>
    )
}

export default LaptopBrands


