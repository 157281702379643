import React from 'react'
import BlogItem from './BlogItem';

const BlogList = ({ blogs }) => {
  return (
    <>
      <h4 style={{marginBottom: '30px'}}>Popular Posts
      </h4>
      <div className='blogList-wrap'>
        {blogs.map((blog) => (
          <BlogItem blog={blog} />
        ))}
      </div>
    </>
  );
};

export default BlogList;
