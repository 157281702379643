import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import EmptyList from './EmptyList'
import { blogList } from './Data'
import whatsapp from '../images/whatsapp.png'
import Footer from '../Footer';
const BlogDesc = () => {
  const { title } = useParams();
  const [blogDesc, setBlogDesc] = useState(title);
  const [blog, setBlog] = useState(null);
  useEffect(() => {
    let blog = blogList.find((blog) => blog.title === blogDesc.replaceAll('-', ' '));
    if (blog) {
      setBlog(blog);
    }
    window.scrollTo(0, 0)
  }, [blogDesc]);

  return (
    <div style={{ backgroundColor: '#f5f5f5', marginTop: '-30px' }}>
      <div className='row container mx-auto justify-content-around'>
        <div className="col-md-8 mb-5" style={{ padding: '20px' }}>
          {blog ? (
            <div className='blog-wrap' style={{ padding: '10px 40px', backgroundColor: '#fff', borderRadius: '5px' }}>
              <header>
                <h2 style={{ textAlign: 'left', padding: '12px 0 0 0' }}>{blog.title}
                  <div className="line"></div>
                </h2>
                  <div className='blog-subCategory'>
                    {blog.subCategory.map((category, i) => (
                      <div key={i} className='blog-desc-chip'>
                        {category}
                      </div>
                    ))}
                  </div>
              </header>
              <img src={blog.cover} alt='cover' />
              <p className='blog-date'>Published {blog.createdAt}</p>
              <div className="share-icons d-flex align-items-center">
                <a target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${blog.title}`}>
                  <i className="bi bi-facebook mx-1" style={{ fontSize: '20px' }}></i>
                </a>
                <a target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${blog.title}`}>
                  <i className="bi bi-linkedin mx-1" style={{ fontSize: '20px' }}></i>
                </a>
                <a target='_blank' href={`https://web.whatsapp.com/send?text={Message}${blog.title}`}>
                <i className="bi bi-whatsapp mx-1" style={{ fontSize: '20px' }}></i>
                </a>
                <a target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${blog.title}`}>
                  <i className="bi bi-instagram mx-1" style={{ fontSize: '20px' }}></i>
                </a>
                <a target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&quote=${blog.title}`}>
                  <i className="bi bi-twitter mx-1" style={{ fontSize: '20px' }}></i>
                </a>
              </div>
              <p className='blog-desc'>{blog.description}</p>
            </div>
          ) : (
            <EmptyList />
          )}
        </div>
        <div className="col-md-4" style={{ padding: '20px' }}>
          <div className="recent-blogs" style={{ backgroundColor: '#fff', borderRadius: '5px' }}>
            <h3 style={{ marginBottom: '0', padding: '23px 20px 15px 20px', borderBottom: '1px solid #f2f2f2' }}>Recent Blogs</h3>
            {blogList.map((v, k) => {
              return (
                <Link to={`/blog/${v.title.replaceAll(' ', '-')}`} style={{ textDecoration: 'none', color: '#111', cursor: 'pointer' }}>
                  <div className="recent-blogs-card" key={k} style={{ maxHeight: '150px', padding: '15px 20px', cursor: 'pointer', borderBottom: '1px solid #f2f2f2' }} onClick={() => { setBlogDesc(v.title.replaceAll(' ', '-')) }}>
                    <div className="recent-blogs-title">
                      {v.title}
                    </div>
                    <div className="blog-desc-chip" style={{ padding: '6px 10px', marginTop: '10px' }}>
                      {v.category}
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
          <div className="popular-categories-right" style={{ marginTop: '10px', backgroundColor: '#fff', borderRadius: '5px' }}>
            <h3 style={{ margin: '30px 0', marginBottom: '0', padding: '23px 20px 15px 20px', borderBottom: '1px solid #f2f2f2' }}>Popular Categories</h3>
            <div className="categories-box d-flex flex-wrap" style={{ textTransform: 'capitalize', padding: '15px', marginTop: '10px' }}>
              {blogList.map((v, k) => {
                return (
                  <Link className='' to={`/blog/tag/${v.category}`} style={{ textDecoration: 'none', color: 'gray' }}>
                    <div className='catagories-link' style={{ padding: '8px 14px', margin: '7px' }}>
                      {v.category}
                    </div>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default BlogDesc
