import './App.css';
import Navbar from './components/Nav';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from './pages/HomePage';
import BopForm from './components/BecomeOurPartnerComponents/BopForm';
import LaptopBrands from './components/Brands/LaptopBrands';
import Laptop from './components/Series/Laptop';
import Product from './components/Products/Product';
import { useEffect } from 'react';
import CeForm from './components/Corporate Enquiry/CeForm';
import Policies from './components/Policies';
import Blog from './components/Blog/Blog';
import BlogDesc from './components/Blog/BlogDesc';
import TagBlogs from './components/Blog/TagBlogs';
import AboutUs from './components/AboutUs';
import { Context } from './components/Context/Context';
import { useState } from 'react';
import ProductProblems from './components/Products/ProductProblems';
import Loader from './components/Loader';
import ProductConfiguration from './components/Products/ProductConfiguration';
import SellProduct from './components/Products/SellProduct';
import Orders from './components/Orders';

function App() {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
    setTimeout(() => {
      setLoader(false);
      setLoaded(true)
    }, 5);
  }, [])
  const [loader, setLoader] = useState(true);
  const [id, setId] = useState();
  const [deviceId, setDeviceId] = useState();
  const [brandName, setBrandName] = useState();
  const [selectedConfig, setSelectedConfig] = useState({});
  const [maxAmount, setMaxAmount] = useState();
  return (
    <>
      {loaded ? <BrowserRouter>
        <div className="App" style={{ paddingTop: '80px' }}>
          <div>
            <Context.Provider value={{ id, setId, brandName, setBrandName, loader, setLoader, deviceId, setDeviceId, maxAmount, setMaxAmount, selectedConfig, setSelectedConfig }}>
              <Navbar />
              <Routes>
                <Route exact path='/' element={<HomePage />} />
                <Route exact path='/about' element={<AboutUs />} />
                <Route exact path='/become-our-partner' element={<BopForm />} />
                <Route exact path='/blog' element={<Blog />} />
                <Route exact path='/blog/:title' element={<BlogDesc />} />
                <Route exact path='/blog/tag/:tag' element={<TagBlogs />} />
                <Route exact path='/sell-laptop' element={<LaptopBrands />} />
                <Route exact path='/sell-laptop/:brandName' element={<Laptop />} />
                <Route exact path='/sell-laptop/:brandName/:series' element={<Product />} />
                <Route exact path='/sell-laptop/:brandName/:series/page/:pageId' element={<ProductConfiguration />} />
                <Route exact path='/sell-laptop/:brandName/:series/problems/' element={<ProductProblems />} />
                <Route exact path='/sell-laptop/:brandName/:series/sellproduct/' element={<SellProduct/>} />
                <Route exact path='/corporate-enquiry' element={<CeForm />} />
                <Route exact path='/policies' element={<Policies />} />
                <Route exact path='/orders' element={<Orders/>} />
              </Routes>
            </Context.Provider>
          </div>
          {/* <Footer />  */}
        </div>
      </BrowserRouter> : <Loader />}
    </>
  );
}

export default App;



//git add .
//git commit
//git push -u origin main
