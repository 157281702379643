import React, { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import Footer from '../Footer';

const Product = () => {
    const { brandName, series } = useParams();
    return (
        <>
            <div className='px-md-5' style={{ padding: '0 30px' }}>
                <h2 className='pb-2 pt-4'>Sell your Product ({`${brandName} - ${series.replace('-', ' ')}`})
                    <div className="line"></div>
                </h2>
                <div className="row mt-4 mb-4">
                    <div className="left-side col-md-8">
                        <div className="container pt-3">
                            <div className="questions text-center d-flex justify-content-center align-items-center" style={{ height: '200px', flexDirection: 'column', fontSize: '18px' }}>
                                Does your Computer Start ?
                                <div className='my-4'>
                                    <Link to={`/sell-laptop/${brandName}/${series}/page/1`} style={{ color: '#111', textDecoration: 'none' }}>
                                        <label htmlFor="inlineRadio1" style={{ cursor: 'pointer', marginRight: '20px' }} onChange={localStorage.setItem('canTurnOn','yes')}>
                                            <div className="form-check form-check-inline me-0" style={{ padding: '4px 40px', border: '1px solid #ccc', borderRadius: '20px' }}>
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                                <span className="form-check-label">Yes</span>
                                            </div>
                                        </label>
                                    </Link>
                                    <Link to={`/sell-laptop/${brandName}/${series}/page/1`} style={{ color: '#111', textDecoration: 'none' }} onChange={localStorage.setItem('canTurnOn','no')}>
                                        <label htmlFor="inlineRadio2" style={{ cursor: 'pointer', marginRight: '20px' }}>
                                            <div className="form-check form-check-inline me-0" style={{ padding: '4px 40px', border: '1px solid #ccc', borderRadius: '20px' }}>
                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                                <span className="form-check-label">No</span>
                                            </div>
                                        </label>
                                    </Link>
                                </div>
                            </div>
                            {/* <div className="d-flex justify-content-center my-3">
                                <button className='btn btn-primary' type='submit'>
                                    <Link to={`/sell-laptop/${brandName}/${series}/page/1`} style={{ color: '#fff', textDecoration: 'none' }}>
                                        Continue →
                                    </Link>
                                </button>
                            </div> */}
                        </div>
                    </div>
                    <div className="right-side col-md-4" style={{ border: '1px solid #ccc', padding: '20px' }}>
                        <div className="d-flex justify-content-center">
                            <i className="bi bi-laptop" style={{ fontSize: '60px', marginRight: '20px' }}></i>
                            <div className='align-self-center' style={{ fontSize: '30px' }}>{series.replace('-', ' ')}</div>
                        </div>
                        <div className="estimated-price text-center">
                            Get Upto: Rs. {localStorage.getItem('maxAmount')}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Product
