import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Context } from '../Context/Context'
import Footer from '../Footer'
import Loader from '../Loader'

const Laptop = () => {
    const [seriesName, setseriesName] = useState('')
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState([]);
    const { brandName, setLoader } = useContext(Context);
    function getCookie() {
        let name = "XSRF-TOKEN=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-XSRF-TOKEN': decodeURIComponent(getCookie()),
        },
        credentials: "include",
        body: JSON.stringify({ d_id: localStorage.getItem('deviceId'), b_id: localStorage.getItem('b-id') })
    };
    const fetchData = () => {
        fetch('https://take2cash-admin.sumayinfotech.com/api/series-listing', options)
            .then((response) => { return response.json() })
            .then((fetchedData) => setData(fetchedData))
            .catch((error) => console.log(error))
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        setLoader(true);
        fetchData();
        setTimeout(() => {
            setLoader(false);
            setLoaded(true)
        }, 2000);
        // eslint-disable-next-line
    }, []);
    return (
        <>
            {loaded ?
                <div>
                    <div className="container-fluid series px-md-5" style={{ padding: '0 30px' }}>
                        <h2 style={{ paddingTop: '30px' }}>Laptop Series
                            <div className="line"></div>
                        </h2>
                        <div className="series-search-box d-flex align-items-center" style={{ fontSize: '16px' }}>
                            <span style={{ marginRight: '15px' }}>Search Box :</span>
                            <input type="text" onChange={(e) => setseriesName(e.target.value)} placeholder='Seach here' />
                        </div>
                        <div className="series-flex" style={{ borderRadius: '5px' }}>
                            {data.filter((val) => {
                                if (seriesName === '') {
                                    return val;
                                }
                                if (val.name.toLowerCase().includes(seriesName)) {
                                    return val;
                                }
                            }).map((value, key) => {
                                return (
                                    <Link to={`/sell-laptop/${brandName}/${value.name.replace(' ', '-')}`}>
                                        <div className="card" key={key} style={{ border: '0', padding: '15px 5px' }} onClick={()=>{localStorage.setItem('series-id', value.id);localStorage.setItem('maxAmount', value.max_amount)}}>
                                            <div className="series-icon mx-auto pt-3" style={{ marginBottom: '-10px' }}>
                                                <img src={'//take2cash-admin.sumayinfotech.com/' + value.image} alt={value.image} height={60} width={60} />
                                            </div>
                                            <div className="card-body text-center">
                                                <h5 className="card-title text-center" style={{ margin: '10px 0' }}>{value.name}</h5>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                    <Footer />
                </div>
                : <Loader />}
        </>
    )
}

export default Laptop
