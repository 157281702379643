import React, { useEffect, useState } from 'react'
import Footer from '../Footer';
import BlogList from './BlogList';
import { blogList } from './Data';
import EmptyList from './EmptyList';

const Blog = () => {
    const [blogs, setBlogs] = useState(blogList);
    const [searchKey, setSearchKey] = useState('');

    // Search submit
    const handleSearchBar = (e) => {
        e.preventDefault();
        handleSearchResults();
    };

    const handleSearchResults = () => {
        const allBlogs = blogList;
        const filteredBlogs = allBlogs.filter((blog) =>
            blog.category.toLowerCase().includes(searchKey.toLowerCase().trim())
        );
        setBlogs(filteredBlogs);
    };
    const handleClearSearch = () => {
        setBlogs(blogList);
        setSearchKey('');
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <div className='container' style={{ marginTop: '30px' }}>
                {/* Page Header */}
                <div className="d-flex justify-content-between flex-wrap" style={{ marginBottom: '40px' }}>
                    <h2>Blogs
                        <div className="line"></div>
                    </h2>
                    {/* Search Bar */}
                    <div className='searchBar-wrap'>
                        <form onSubmit={handleSearchBar}>
                            <input
                                type='text'
                                placeholder='Search By Category'
                                value={searchKey}
                                onChange={(e) => setSearchKey(e.target.value)}
                            />
                            {searchKey && <span onClick={handleClearSearch}>X</span>}
                            <button>Go</button>
                        </form>
                    </div>
                </div>
                {/* Blog List & Empty View */}
                {!blogs.length ? <EmptyList /> : <BlogList blogs={blogs} />}
            </div>
            <Footer/>
        </>
    );
}

export default Blog




// import EmptyList from '../../components/common/EmptyList';
// import BlogList from '../../components/Home/BlogList';
// import Header from '../../components/Home/Header';
// import SearchBar from '../../components/Home/SearchBar';