import React, { useContext, useEffect, useState } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { Context } from '../Context/Context';
import Footer from '../Footer'
import $ from "jquery";

const ProductConfiguration = () => {
    const navigate = useNavigate();
    const { brandName, series, pageId } = useParams();
    const [data, setData] = useState(false);
    const [toast, setToast] = useState('');
    const { setLoader, maxAmount, selectedConfig, setSelectedConfig } = useContext(Context);
    function getCookie() {
        let name = "XSRF-TOKEN=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    const options = {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-XSRF-TOKEN': decodeURIComponent(getCookie()),
        },
        body: JSON.stringify({ d_id: localStorage.getItem('deviceId'), b_id: localStorage.getItem('b-id') })
    }
    const fetchData = () => {
        fetch(`https://take2cash-admin.sumayinfotech.com/api/config-listing?page=${pageId}`, options)
            .then((response) => { return response.json() })
            .then((fetchedData) => setData(fetchedData))
            .catch((error) => console.log(error))
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
        // eslint-disable-next-line
    }, [pageId]);
    const abc = (e) => {
        e.preventDefault();
        if ($(".config-dropdown").filter((e,v)=>!v.value).length) {
            setToast('show')
        }
        else{
            navigate(`${data.last_page > (parseInt(pageId)) ? `/sell-laptop/${brandName}/${series}/page/${parseInt(pageId) + 1}` : `/sell-laptop/${brandName}/${series}/problems/`}`);
        }
    }
    const handleRadio = (e) => {
        var obj = { ...JSON.parse(localStorage.getItem('selectedConfig')), };
        obj[e.target.dataset.qid] = e.target.id;
        // setSelectedConfig(obj);
        localStorage.setItem('selectedConfig', JSON.stringify(obj));
        console.log(obj);
    }
    const handleSelect = (e) => {
        var obj = { ...JSON.parse(localStorage.getItem('selectedConfig')), };
        obj[e.target.dataset.qid] = e.target.selectedOptions[0].id;
        setSelectedConfig(obj);
        localStorage.setItem('selectedConfig', JSON.stringify(obj));
        console.log(obj);

    }
    return (
        <>
            <div className='px-md-5' style={{ padding: '0 30px' }}>
                <h2 className='pb-2 pt-4'>Sell your Product ({`${brandName} - ${series.replace('-', ' ')}`})
                    <div className="line"></div>
                </h2>
                <div className="row mt-4 mb-4">
                    <form className="left-side col-md-8" onSubmit={abc}>
                        <div className="container pt-3">
                            <div className="text-center" style={{ fontSize: '22px' }}>Device Configuration</div>
                            {data && data.data.map((a, b) => {
                                return (
                                    <>
                                        <div className="questions" style={{ marginTop: '10px' }}>
                                            {a.name}
                                            <div className='mt-2 mb-4'>
                                                {data && a.config_values.length <= 4 ?
                                                    data && a.config_values.map((c, d) => {
                                                        return (
                                                            <label htmlFor={c.id} style={{ cursor: 'pointer', margin: '10px 10px 10px 0' }}>
                                                                <div className="form-check form-check-inline me-0 configuration-radio-box" style={{ padding: '4px 40px', border: '1px solid #ccc', borderRadius: '5px' }}>
                                                                    <input className="form-check-input configuration-radio-input" type="radio" name={c.configuration_id} id={c.id} value={c.value} required={true} data-qid={a.id} onChange={handleRadio} />
                                                                    <span className="form-check-label" style={{ color: 'gray' }}>{c.value}</span>
                                                                </div>
                                                            </label>
                                                        )
                                                    })
                                                    :
                                                    <div className="row w-100">
                                                        <div className="col-12 bop-form-input-box">
                                                            <select className="form-select config-dropdown" aria-label="Default select example" id='config-dropdown' data-qid={a.id} onChange={handleSelect}>
                                                                <option selected value=''>--Choose--</option>
                                                                {data && a.config_values.map((c, d) => {
                                                                    return (
                                                                        <option id={c.id} value={c.value}>{c.value}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                            <div className="d-flex justify-content-center my-3">
                                <button className='btn btn-primary' type='submit'>
                                    Continue →
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="right-side col-md-4" style={{ border: '1px solid #ccc', padding: '20px' }}>
                        <div className="d-flex justify-content-center">
                            <i className="bi bi-laptop" style={{ fontSize: '60px', marginRight: '20px' }}></i>
                            <div className='align-self-center' style={{ fontSize: '30px' }}>{series.replace('-', ' ')}</div>
                        </div>
                        <div className="estimated-price text-center">
                            Get Upto: Rs. {localStorage.getItem('maxAmount')}
                        </div>
                    </div>
                </div>
            </div >
            <div className="toast-container position-fixed bottom-0 end-0 p-3" style={{ zIndex: '9999999999999 !important' }}>
                <div id="liveToast" className={`toast ${toast}`} role="alert" aria-live="assertive" aria-atomic="true" delay={5000} autohide={true}>
                    <div className="toast-header" style={{ background: '#ff432d', color: '#fff' }}>
                        <i class="bi bi-check2-circle" style={{ fontSize: '20px' }}></i>
                        <span className="me-auto ms-2">Bootstrap</span>
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="toast" onClick={() => { setToast(''); }} aria-label="Close"></button>
                    </div>
                    <div className="toast-body">
                        Select any value in dropdown
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ProductConfiguration
