import React from 'react'
import { Link } from 'react-router-dom'
const BlogItem = ({
  blog: {
    description,
    title,
    createdAt,
    authorName,
    authorAvatar,
    cover,
    category,
    id,
  },
}) => {
  return (
    <div className='blogItem-wrap'>
      <Link className='blogItem-link' to={`/blog/${title.replaceAll(' ','-')}`}>
        <img className='blogItem-cover' src={cover} alt='cover' />
      </Link>
      <p className='chip'>{category}</p>
      <h4 style={{ padding: '10px 0' }}>{title}</h4>
      <p className='blogItem-desc'>{description}</p>
      <footer>
        <div className='blogItem-author'>
          <img src={authorAvatar} alt='avatar' />
          <div>
            <h6>{authorName}</h6>
            <p>{createdAt}</p>
          </div>
        </div>
        <Link className='blogItem-link' to={`/blog/${title.replaceAll(' ','-')}`}>
          ➝
        </Link>
      </footer>
    </div>
  );
};

export default BlogItem;