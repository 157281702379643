import React, { useEffect, useRef, useState } from 'react'

const Orders = () => {
    const [blockNo, setBlockNo] = useState('');
    const mapApi = 'https://maps.googleapis.com/maps/api/js';
    // const geocode = 'https://maps.googleapis.com/maps/api/geocode/json';
    const key = 'AIzaSyCW9qpmLLexwnKdLA-LRHVM63hulApo2qE';
    function loadAsyncScript(src) {
        return new Promise(resolve => {
            const script = document.createElement('script');
            Object.assign(script, {
                type: 'text/javascript',
                async: true,
                src
            })
            script.addEventListener('load', () => resolve(script));
            document.head.appendChild(script);
        })
    }
    const [addressCity, setAddressCity] = useState('');
    const [addressState, setAddressState] = useState('');
    const [addressZip, setAddressZip] = useState('');
    const [addressCountry, setAddressCountry] = useState('');
    const extractAddress = (place) => {
        const address = {
            city: '',
            state: '',
            zip: '',
            country: '',
            plain() {
                const city = this.city ? this.city + ', ' : '';
                const zip = this.zip ? this.zip + ', ' : '';
                const state = this.state ? this.state + ', ' : '';
                return city + zip + state + this.country;
            }
        }
        if (!Array.isArray(place?.address_components)) {
            return address;
        }
        place.address_components.forEach(component => {
            const types = component.types;
            const value = component.long_name;
            if (types.includes('locality')) {
                address.city = value;
                setAddressCity(value);
            }
            if (types.includes('administrative_area_level_2')) {
                address.state = value;
                setAddressState(value);
            }
            if (types.includes('postal_code')) {
                address.zip = value;
                setAddressZip(value);
            }
            if (types.includes('country')) {
                address.country = value;
                setAddressCountry(value);
            }
        })
    }
    const searchAddress = useRef(null);
    const initMapScript = () => {
        if (window.google) {
            return Promise.resolve();
        }
        const src = `${mapApi}?key=${key}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    }
    const onChangeAddress = (autocomplete) => {
        const location = autocomplete.getPlace();
    }
    const initAutocomplete = () => {
        if (!searchAddress.current) return;
        const autocomplete = new window.google.maps.places.Autocomplete(searchAddress.current);
        autocomplete.setFields(['address_component', 'geometry']);
        autocomplete.addListener('place_changed', () => onChangeAddress(autocomplete));
    }
    useEffect(() => {
        initMapScript().then(() => initAutocomplete())
    }, [])
    return (
        <div className='container' style={{ paddingTop: '20px' }}>
            <h2 style={{ paddingBottom: '20px' }}>Orders
                <div className="line"></div>
            </h2>
            <div className="row mb-4">
                <h3 style={{ paddingBottom: '20px' }}>Upcoming Orders
                    <div className="line"></div>
                </h3>
                <div className="d-flex flex-wrap">
                    <div className="card" style={{ margin: '5px' }}>
                        <div className="d-flex flex-wrap">
                            <div className="orders-image d-flex justify-content-center align-items-center" style={{ padding: '25px', background: '#f2f2f2', width: '150px', height: 'auto', borderRadius: '5px' }}>
                                <i className='bi bi-laptop' style={{ fontSize: '50px' }}></i>
                            </div>
                            <div className="order-details" style={{ padding: '20px' }}>
                                <div className="order-product-name" style={{ paddingBottom: '10px', fontSize: '18px' }}>Product Name : I-series</div>
                                <div className="order-product-schedule" style={{ paddingBottom: '2px', color: 'gray', fontSize: '14px' }}>Scheduled Date & Time : 01/09/2022 00:00 am</div>
                                <div className="order-product-address" style={{ paddingBottom: '10px', color: 'gray', fontSize: '14px' }}>Address : Adipur, Gandhidham, Kutch</div>
                                <div className="order-edit-btn btn btn-primary" style={{ fontSize: '14px' }} data-bs-toggle="modal" data-bs-target="#orderEditDetails"><i className="bi bi-pencil-square"></i> Edit</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="orderEditDetails" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="orderEditDetailsLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="orderEditDetailsLabel">Edit Details</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body bop-container">
                            <div className='row' style={{ width: '100%' }}>
                                <div className="col-md-12 bop-form-input-box">
                                    <input type="text" className="form-control" aria-label="Block No." required value={blockNo} onChange={() => { setBlockNo(blockNo) }} />
                                    <span>House No. / Block No.</span>
                                </div>
                            </div>
                            <div className='row' style={{ width: '100%' }}>
                                <div className="col-12 bop-form-input-box" style={{ position: 'relative' }}>
                                    <input type="text" className="form-control" ref={searchAddress} name="" id="" placeholder='Enter your location' />
                                </div>
                            </div>
                            <div className='row' style={{ width: '100%' }}>
                                <div className="col-md-12">
                                    <span>Appointment Date and Time : </span><input type="datetime-local" required />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save Edit</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <h3 style={{ paddingBottom: '20px' }}>Recent Orders
                    <div className="line"></div>
                </h3>
                <div className="d-flex flex-wrap">
                    <div className="card" style={{ margin: '5px' }}>
                        <div className="d-flex flex-wrap">
                            <div className="orders-image d-flex justify-content-center align-items-center" style={{ padding: '25px', background: '#f2f2f2', width: '150px', height: 'auto', borderRadius: '5px' }}>
                                <i className='bi bi-laptop' style={{ fontSize: '50px' }}></i>
                            </div>
                            <div className="order-details" style={{ padding: '20px' }}>
                                <div className="order-product-name" style={{ paddingBottom: '10px', fontSize: '18px' }}>Product Name : I-series</div>
                                <div className="order-product-schedule" style={{ paddingBottom: '2px', color: 'gray', fontSize: '14px' }}>Scheduled Date & Time : 01/09/2022 00:00 am</div>
                                <div className="order-product-address" style={{ paddingBottom: '10px', color: 'gray', fontSize: '14px' }}>Address : Adipur, Gandhidham, Kutch</div>
                                <div className="order-edit-btn btn btn-primary" style={{ fontSize: '14px' }}><i className="bi bi-pencil-square"></i> Edit</div>
                            </div>
                        </div>
                    </div>
                    <div className="card" style={{ margin: '5px' }}>
                        <div className="d-flex flex-wrap">
                            <div className="orders-image d-flex justify-content-center align-items-center" style={{ padding: '25px', background: '#f2f2f2', width: '150px', height: 'auto', borderRadius: '5px' }}>
                                <i className='bi bi-laptop' style={{ fontSize: '50px' }}></i>
                            </div>
                            <div className="order-details" style={{ padding: '20px' }}>
                                <div className="order-product-name" style={{ paddingBottom: '10px', fontSize: '18px' }}>Product Name : I-series</div>
                                <div className="order-product-schedule" style={{ paddingBottom: '2px', color: 'gray', fontSize: '14px' }}>Scheduled Date & Time : 01/09/2022 00:00 am</div>
                                <div className="order-product-address" style={{ paddingBottom: '10px', color: 'gray', fontSize: '14px' }}>Address : Adipur, Gandhidham, Kutch</div>
                                <div className="order-edit-btn btn btn-primary" style={{ fontSize: '14px' }}><i className="bi bi-pencil-square"></i> Edit</div>
                            </div>
                        </div>
                    </div>
                    <div className="card" style={{ margin: '5px' }}>
                        <div className="d-flex flex-wrap">
                            <div className="orders-image d-flex justify-content-center align-items-center" style={{ padding: '25px', background: '#f2f2f2', width: '150px', height: 'auto', borderRadius: '5px' }}>
                                <i className='bi bi-laptop' style={{ fontSize: '50px' }}></i>
                            </div>
                            <div className="order-details" style={{ padding: '20px' }}>
                                <div className="order-product-name" style={{ paddingBottom: '10px', fontSize: '18px' }}>Product Name : I-series</div>
                                <div className="order-product-schedule" style={{ paddingBottom: '2px', color: 'gray', fontSize: '14px' }}>Scheduled Date & Time : 01/09/2022 00:00 am</div>
                                <div className="order-product-address" style={{ paddingBottom: '10px', color: 'gray', fontSize: '14px' }}>Address : Adipur, Gandhidham, Kutch</div>
                                <div className="order-edit-btn btn btn-primary" style={{ fontSize: '14px' }}><i className="bi bi-pencil-square"></i> Edit</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Orders
